@import '../../../css/general_styling';

.ViewSwitch {
  display: flex;
  justify-content: right;
  justify-items: center;
}

.TableContainer {
  margin-top: 2em;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  justify-items: center;
}

.filtersContainer {
  width: 200px;
}
